<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268 68" fill="currentColor">
        <path d="M 41.737 42.682 C 34.571 42.649 28.778 36.837 28.767 29.671 C 28.767 13.792 40.627 1.202 41.1 0.678 L 41.737 0 L 42.384 0.678 C 42.888 1.202 54.717 13.72 54.717 29.671 C 54.706 36.841 48.906 42.655 41.737 42.682 M 41.737 2.652 C 39.301 5.54 30.565 16.608 30.565 29.671 C 30.565 38.303 39.91 43.698 47.384 39.381 C 50.854 37.378 52.991 33.677 52.991 29.671 C 53.042 16.557 44.265 5.529 41.737 2.652" />
        <path d="M 53.885 58.966 C 52.75 58.968 51.621 58.822 50.524 58.534 L 50.997 56.787 C 51.938 57.042 52.91 57.171 53.885 57.168 C 67.02 57.168 78.037 48.442 80.904 45.955 C 78.027 43.478 66.948 34.752 53.885 34.752 C 49.216 34.764 45.039 37.66 43.392 42.029 L 41.737 41.392 C 43.646 36.331 48.476 32.968 53.885 32.933 C 69.764 32.933 82.354 44.793 82.878 45.266 L 83.556 45.914 L 82.878 46.571 C 82.354 47.075 69.836 58.904 53.885 58.904" />
        <path d="M 53.854 48.304 C 52.794 48.305 51.738 48.178 50.709 47.924 L 51.151 46.166 C 54.977 47.153 59.039 46.034 61.819 43.227 C 71.069 33.977 72.713 19.979 72.991 16.197 C 69.198 16.475 55.2 18.14 45.961 27.369 C 42.277 31.094 41.635 36.861 44.409 41.305 L 42.888 42.261 C 39.671 37.11 40.415 30.422 44.687 26.105 C 55.92 14.882 73.196 14.388 73.926 14.388 L 74.872 14.388 L 74.872 15.324 C 74.872 16.053 74.388 33.289 63.114 44.573 C 60.654 47.012 57.329 48.379 53.864 48.376" />
        <path d="M 29.64 48.349 C 26.196 48.348 22.893 46.976 20.462 44.536 C 9.157 33.231 8.715 16.016 8.705 15.287 L 8.705 14.351 L 9.651 14.351 C 10.38 14.351 27.657 14.886 38.89 26.109 C 43.189 30.429 43.942 37.141 40.709 42.306 L 39.188 41.35 C 41.954 36.906 41.307 31.145 37.626 27.424 C 28.376 18.175 14.388 16.53 10.596 16.253 C 10.874 20.035 12.497 34.002 21.768 43.283 C 24.496 46.042 28.464 47.183 32.24 46.294 L 32.652 48.051 C 31.665 48.284 30.655 48.401 29.64 48.401" />
        <path d="M 29.671 58.966 C 13.792 58.966 1.202 47.116 0.678 46.633 L 0 45.975 L 0.678 45.328 C 1.202 44.824 13.72 32.995 29.671 32.995 C 34.989 32.975 39.776 36.213 41.737 41.155 L 40.062 41.813 C 38.382 37.528 34.243 34.715 29.64 34.732 C 16.506 34.732 5.488 43.447 2.621 45.934 C 5.498 48.411 16.578 57.147 29.64 57.147 C 30.77 57.151 31.892 56.98 32.97 56.643 L 33.515 58.38 C 32.264 58.771 30.961 58.968 29.651 58.966" />
        <path d="M 36.465 62.203 C 28.785 58.11 28.416 47.237 35.802 42.634 C 43.187 38.03 52.787 43.147 53.081 51.845 C 53.226 56.109 50.957 60.091 47.215 62.142 L 46.341 60.559 C 52.772 57.082 53.027 47.948 46.8 44.117 C 40.573 40.287 32.535 44.633 32.332 51.941 C 32.231 55.522 34.156 58.855 37.307 60.559 Z" />
        <path d="M 41.737 57.965 C 36.752 57.965 33.637 52.569 36.129 48.253 C 38.622 43.936 44.852 43.936 47.344 48.253 C 47.913 49.237 48.212 50.354 48.212 51.49 C 48.207 55.064 45.31 57.959 41.737 57.965 M 41.737 46.814 C 38.138 46.814 35.887 50.711 37.688 53.828 C 39.487 56.946 43.987 56.946 45.787 53.828 C 46.197 53.117 46.413 52.311 46.413 51.49 C 46.413 48.908 44.32 46.814 41.737 46.814" />
        <path d="M 41.737 68 C 37.195 68 34.358 63.084 36.628 59.151 C 38.898 55.219 44.576 55.219 46.846 59.151 C 47.364 60.048 47.636 61.066 47.636 62.101 C 47.636 65.359 44.995 68 41.737 68 M 41.737 58.01 C 38.572 58.01 36.594 61.437 38.177 64.177 C 39.758 66.918 43.715 66.918 45.297 64.177 C 45.658 63.552 45.848 62.843 45.848 62.121 C 45.848 59.851 44.007 58.01 41.737 58.01" />
        <path d="M 115.54 40.387 C 110.606 45.316 102.186 43.054 100.385 36.316 C 98.584 29.579 104.752 23.418 111.488 25.227 C 113.043 25.645 114.46 26.47 115.59 27.617 L 117.11 26.097 C 111.025 19.967 100.586 22.724 98.321 31.059 C 96.055 39.394 103.662 47.055 112.013 44.85 C 113.944 44.34 115.703 43.324 117.11 41.907 Z" style="fill-rule:evenodd" />
        <path d="M 256.78 22.767 C 248.143 22.767 242.745 32.117 247.064 39.597 C 251.382 47.077 262.179 47.077 266.497 39.597 C 267.484 37.888 268.002 35.95 268 33.977 C 268 27.782 262.975 22.761 256.78 22.767 M 256.78 43.037 C 249.806 43.037 245.447 35.487 248.934 29.447 C 252.421 23.407 261.139 23.407 264.627 29.447 C 265.422 30.824 265.84 32.387 265.84 33.977 C 265.84 38.981 261.784 43.037 256.78 43.037" style="fill-rule:evenodd" />
        <polygon points="155.08 23.187 152.79 23.187 152.79 44.777 167.17 44.777 167.17 42.797 155.08 42.797 155.08 23.187" />
        <path d="M 211.61 25.157 C 209.76 23.734 207.46 23.024 205.13 23.157 L 197.05 23.157 L 197.05 44.747 L 199.34 44.747 L 199.34 37.957 L 205.13 37.957 C 207.46 38.084 209.758 37.375 211.61 35.957 C 213.165 34.618 214.017 32.637 213.92 30.587 C 214.016 28.511 213.149 26.508 211.57 25.157 M 209.94 34.547 C 208.519 35.577 206.782 36.076 205.03 35.957 L 199.3 35.957 L 199.3 25.157 L 205.03 25.157 C 206.78 25.04 208.516 25.535 209.94 26.557 C 211.091 27.558 211.715 29.034 211.63 30.557 C 211.729 32.082 211.102 33.564 209.94 34.557" />
        <path d="M 234.33 34.207 C 233.075 33.745 231.793 33.361 230.49 33.057 C 229.15 32.727 228.09 32.417 227.29 32.147 C 226.54 31.902 225.856 31.488 225.29 30.937 C 224.752 30.382 224.466 29.629 224.5 28.857 C 224.485 27.737 225.024 26.681 225.94 26.037 C 227.208 25.22 228.706 24.834 230.21 24.937 C 232.314 24.955 234.369 25.576 236.13 26.727 L 236.9 24.907 C 235.959 24.277 234.923 23.804 233.83 23.507 C 232.654 23.167 231.435 22.996 230.21 22.997 C 228.691 22.952 227.178 23.224 225.77 23.797 C 224.694 24.237 223.773 24.985 223.12 25.947 C 222.537 26.837 222.234 27.882 222.25 28.947 C 222.177 30.094 222.534 31.228 223.25 32.127 C 223.923 32.9 224.774 33.498 225.73 33.867 C 227.001 34.344 228.3 34.742 229.62 35.057 C 230.692 35.304 231.751 35.608 232.79 35.967 C 233.512 36.221 234.17 36.626 234.72 37.157 C 235.258 37.705 235.544 38.45 235.51 39.217 C 235.519 40.322 234.975 41.358 234.06 41.977 C 232.757 42.778 231.236 43.152 229.71 43.047 C 228.344 43.049 226.988 42.802 225.71 42.317 C 224.546 41.92 223.481 41.275 222.59 40.427 L 221.7 42.177 C 222.661 43.107 223.83 43.793 225.11 44.177 C 226.593 44.691 228.151 44.952 229.72 44.947 C 231.249 44.993 232.77 44.724 234.19 44.157 C 235.279 43.731 236.219 42.994 236.89 42.037 C 237.474 41.146 237.78 40.102 237.77 39.037 C 237.838 37.905 237.482 36.789 236.77 35.907 C 236.095 35.139 235.244 34.546 234.29 34.177" />
        <path d="M 186.06 23.177 C 184.815 27.742 182.605 31.988 179.58 35.627 C 176.551 31.991 174.341 27.744 173.1 23.177 L 171.01 23.177 C 172.33 28.444 174.883 33.322 178.46 37.407 L 178.46 44.777 L 180.71 44.777 L 180.71 37.407 C 184.281 33.319 186.83 28.442 188.15 23.177 Z" />
        <path d="M 133.71 23.187 L 132.99 23.947 C 132.64 24.307 124.53 32.887 124.53 43.787 C 124.53 44.127 124.53 44.447 124.59 44.787 L 126.59 44.787 C 126.55 44.455 126.53 44.121 126.53 43.787 C 126.543 41.999 126.775 40.219 127.22 38.487 L 140.22 38.487 C 140.674 40.217 140.91 41.998 140.92 43.787 C 140.926 44.121 140.906 44.456 140.86 44.787 L 142.86 44.787 C 142.901 44.455 142.921 44.121 142.92 43.787 C 142.92 32.867 134.79 24.327 134.44 23.937 Z M 127.83 36.487 C 129.173 32.728 131.166 29.235 133.72 26.167 C 136.272 29.236 138.266 32.729 139.61 36.487 Z" />
    </svg>
</template>
