<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 84 68" fill="currentColor">
        <path d="M 41.737 42.682 C 34.571 42.649 28.778 36.837 28.767 29.671 C 28.767 13.792 40.627 1.202 41.1 0.678 L 41.737 0 L 42.384 0.678 C 42.888 1.202 54.717 13.72 54.717 29.671 C 54.706 36.841 48.906 42.655 41.737 42.682 M 41.737 2.652 C 39.301 5.54 30.565 16.608 30.565 29.671 C 30.565 38.303 39.91 43.698 47.384 39.381 C 50.854 37.378 52.991 33.677 52.991 29.671 C 53.042 16.557 44.265 5.529 41.737 2.652" />
        <path d="M 53.885 58.966 C 52.75 58.968 51.621 58.822 50.524 58.534 L 50.997 56.787 C 51.938 57.042 52.91 57.171 53.885 57.168 C 67.02 57.168 78.037 48.442 80.904 45.955 C 78.027 43.478 66.948 34.752 53.885 34.752 C 49.216 34.764 45.039 37.66 43.392 42.029 L 41.737 41.392 C 43.646 36.331 48.476 32.968 53.885 32.933 C 69.764 32.933 82.354 44.793 82.878 45.266 L 83.556 45.914 L 82.878 46.571 C 82.354 47.075 69.836 58.904 53.885 58.904" />
        <path d="M 53.854 48.304 C 52.794 48.305 51.738 48.178 50.709 47.924 L 51.151 46.166 C 54.977 47.153 59.039 46.034 61.819 43.227 C 71.069 33.977 72.713 19.979 72.991 16.197 C 69.198 16.475 55.2 18.14 45.961 27.369 C 42.277 31.094 41.635 36.861 44.409 41.305 L 42.888 42.261 C 39.671 37.11 40.415 30.422 44.687 26.105 C 55.92 14.882 73.196 14.388 73.926 14.388 L 74.872 14.388 L 74.872 15.324 C 74.872 16.053 74.388 33.289 63.114 44.573 C 60.654 47.012 57.329 48.379 53.864 48.376" />
        <path d="M 29.64 48.349 C 26.196 48.348 22.893 46.976 20.462 44.536 C 9.157 33.231 8.715 16.016 8.705 15.287 L 8.705 14.351 L 9.651 14.351 C 10.38 14.351 27.657 14.886 38.89 26.109 C 43.189 30.429 43.942 37.141 40.709 42.306 L 39.188 41.35 C 41.954 36.906 41.307 31.145 37.626 27.424 C 28.376 18.175 14.388 16.53 10.596 16.253 C 10.874 20.035 12.497 34.002 21.768 43.283 C 24.496 46.042 28.464 47.183 32.24 46.294 L 32.652 48.051 C 31.665 48.284 30.655 48.401 29.64 48.401" />
        <path d="M 29.671 58.966 C 13.792 58.966 1.202 47.116 0.678 46.633 L 0 45.975 L 0.678 45.328 C 1.202 44.824 13.72 32.995 29.671 32.995 C 34.989 32.975 39.776 36.213 41.737 41.155 L 40.062 41.813 C 38.382 37.528 34.243 34.715 29.64 34.732 C 16.506 34.732 5.488 43.447 2.621 45.934 C 5.498 48.411 16.578 57.147 29.64 57.147 C 30.77 57.151 31.892 56.98 32.97 56.643 L 33.515 58.38 C 32.264 58.771 30.961 58.968 29.651 58.966" />
        <path d="M 36.465 62.203 C 28.785 58.11 28.416 47.237 35.802 42.634 C 43.187 38.03 52.787 43.147 53.081 51.845 C 53.226 56.109 50.957 60.091 47.215 62.142 L 46.341 60.559 C 52.772 57.082 53.027 47.948 46.8 44.117 C 40.573 40.287 32.535 44.633 32.332 51.941 C 32.231 55.522 34.156 58.855 37.307 60.559 Z" />
        <path d="M 41.737 57.965 C 36.752 57.965 33.637 52.569 36.129 48.253 C 38.622 43.936 44.852 43.936 47.344 48.253 C 47.913 49.237 48.212 50.354 48.212 51.49 C 48.207 55.064 45.31 57.959 41.737 57.965 M 41.737 46.814 C 38.138 46.814 35.887 50.711 37.688 53.828 C 39.487 56.946 43.987 56.946 45.787 53.828 C 46.197 53.117 46.413 52.311 46.413 51.49 C 46.413 48.908 44.32 46.814 41.737 46.814" />
        <path d="M 41.737 68 C 37.195 68 34.358 63.084 36.628 59.151 C 38.898 55.219 44.576 55.219 46.846 59.151 C 47.364 60.048 47.636 61.066 47.636 62.101 C 47.636 65.359 44.995 68 41.737 68 M 41.737 58.01 C 38.572 58.01 36.594 61.437 38.177 64.177 C 39.758 66.918 43.715 66.918 45.297 64.177 C 45.658 63.552 45.848 62.843 45.848 62.121 C 45.848 59.851 44.007 58.01 41.737 58.01" />
    </svg>
</template>
