<script setup lang="ts">
import dayjs from 'dayjs'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { Link, usePage } from '@inertiajs/vue3'
import ApplicationLogo from '@/Components/ApplicationLogo.vue'
import FacebookIcon from '../Icons/FacebookIcon.vue'
import InstagramIcon from '../Icons/InstagramIcon.vue'
import { useHelpers } from '@/Composables/helpers'
import { LinkType } from '@enums/link-type.enum'

const page = usePage()
const { t } = useI18n()
const { socialLink } = useHelpers()

const categories = computed(() => page.props.layout.categories.filter((category) => ['jewellery', 'kids-jewellery'].includes(category.code)))
</script>

<template>
    <footer class="bg-primary-950">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="py-20 xl:grid xl:grid-cols-4 xl:gap-8">
                <div>
                    <ApplicationLogo class="w-auto h-12 text-grayscale-100" />

                    <ul class="mt-6 text-p2 text-grayscale-200">
                        <li>Calypso OÜ</li>
                        <li>Reg. nr: 14794707</li>
                        <li>
                            <a class="text-grayscale-200" href="mailto:info@calypso.ee">info@calypso.ee</a>
                        </li>
                    </ul>

                    <!-- Social -->
                    <ul class="flex mt-5 -ml-4">
                        <li class="nav-item">
                            <a class="block px-4 py-4 text-grayscale-200 hover:text-grayscale-100" :href="socialLink(LinkType.Facebook)" target="blank" aria-label="Facebook">
                                <FacebookIcon class="w-4 h-4" />
                            </a>
                        </li>

                        <li class="-ml-4 nav-item">
                            <a class="block px-4 py-4 text-grayscale-200 hover:text-grayscale-100" :href="socialLink(LinkType.Instagram)" target="blank" aria-label="Instagram">
                                <InstagramIcon class="w-4 h-4" />
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="grid grid-cols-2 gap-8 mt-12 xl:col-span-3 md:mt-16 xl:mt-0">
                    <div class="space-y-12 md:space-y-0 md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <div class="font-medium text-p1 text-grayscale-100">{{ t('layout.footer.support') }}</div>
                            <ul role="list" class="mt-6 space-y-3">
                                <li class="text-p2">
                                    <Link :href="route('contact')" class="text-grayscale-200 hover:text-grayscale-100">{{ t('layout.footer.contacts') }}</Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('faq')" class="text-grayscale-200 hover:text-grayscale-100">{{ t('layout.footer.faq') }}</Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('payment-methods')" class="text-grayscale-200 hover:text-grayscale-100">{{ t('layout.footer.payment-methods') }}</Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('hire-purchase')" class="text-grayscale-200 hover:text-grayscale-100">{{ t('components.menu.hire-purchase') }}</Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('shipping')" class="text-grayscale-200 hover:text-grayscale-100">{{ t('layout.footer.shipping') }}</Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('returns')" class="text-grayscale-200 hover:text-grayscale-100">{{ t('layout.footer.return-of-goods') }}</Link>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <div class="font-medium text-p1 text-grayscale-100">{{ t('layout.footer.company') }}</div>
                            <ul role="list" class="mt-6 space-y-3">
                                <li class="text-p2">
                                    <a href="#" class="text-grayscale-200 hover:text-grayscale-100">{{ t('layout.footer.our-story') }}</a>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('reviews', { type: 'shop' })" class="text-grayscale-200 hover:text-grayscale-100">{{ t('layout.footer.reviews') }}</Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('terms-of-use')" class="text-grayscale-200 hover:text-grayscale-100">{{ t('layout.footer.terms-of-use') }}</Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('privacy-policy')" class="text-grayscale-200 hover:text-grayscale-100">{{ t('layout.footer.privacy-policy') }}</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="space-y-12 md:space-y-0 md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <div class="font-medium text-p1 text-grayscale-100">{{ t('layout.footer.shop') }}</div>
                            <ul role="list" class="mt-6 space-y-3">
                                <li v-for="category in categories" :key="category.id" class="text-p2">
                                    <Link :href="route('shop.category', category.slug_path)" class="text-grayscale-200 hover:text-grayscale-100">
                                    {{ category.name }}
                                    </Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('shop.outlet')" class="text-grayscale-200 hover:text-grayscale-100">{{ t('layout.footer.outlet') }}</Link>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <div class="font-medium text-p1 text-grayscale-100">{{ t('layout.footer.brands') }}</div>
                            <ul role="list" class="mt-6 space-y-3">
                                <li class="text-p2">
                                    <Link :href="route('brands')" class="text-grayscale-200 hover:text-grayscale-100">{{ t('layout.footer.all-brands') }}</Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('shop.brand', 'sokolov')" class="text-grayscale-200 hover:text-grayscale-100">SOKOLOV</Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('shop.brand', 'alberto-martini')" class="text-grayscale-200 hover:text-grayscale-100">Alberto Martini</Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('shop.brand', 'meilys')" class="text-grayscale-200 hover:text-grayscale-100">Meilys</Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('shop.brand', 'sentiell')" class="text-grayscale-200 hover:text-grayscale-100">Sentiell</Link>
                                </li>

                                <li class="text-p2">
                                    <Link :href="route('shop.brand', 'e-crystal')" class="text-grayscale-200 hover:text-grayscale-100">e-Crystal</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pt-6 pb-10 border-t border-primary-900">
                <p class="text-grayscale-400 text-p2">
                    Kõik õigused kaitstud &copy; {{ dayjs().format('YYYY') }}
                    Calypso
                </p>
            </div>
        </div>
    </footer>
</template>
