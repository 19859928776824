<script setup lang="ts">
import { ref, onUnmounted, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Link, usePage } from '@inertiajs/vue3'
import { Dialog, DialogPanel, Popover, PopoverButton, PopoverGroup, PopoverPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import ApplicationLogo from '../ApplicationLogo.vue'
import ApplicationLogoIcon from '../ApplicationLogoIcon.vue'
import { useCartStore } from '@/Stores/cart.store'
import { Cog6ToothIcon, UserIcon, HeartIcon, ShoppingCartIcon, XMarkIcon, Bars3Icon } from '@heroicons/vue/24/outline'

const page = usePage()
const { t } = useI18n()
const cart = useCartStore()

const showMenu = ref(false)

const jewelleryCategory = page.props.layout.categories.find(category => category.code === 'jewellery')
const kidsJewelleryCategory = page.props.layout.categories.find(category => category.code === 'kids-jewellery')
const earringsCategory = jewelleryCategory?.descendants?.find(category => category.code === 'earrings')
const pendantsCategory = jewelleryCategory?.descendants?.find(category => category.code === 'pendants')
const collectionsCategory = page.props.layout.categories.find(category => category.code === 'collections')
const kabbalahCategory = jewelleryCategory?.descendants?.find(category => category.code === 'kabbalah')
const silverwareCategory = page.props.layout.categories.find(category => category.code === 'silverware')

const onNavigate = () => {
    showMenu.value = false
}

onMounted(() => {
    document.addEventListener('inertia:start', onNavigate)
})

onUnmounted(() => {
    document.removeEventListener('inertia:start', onNavigate)
})
</script>

<template>
    <div class="bg-white">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="border-b border-gray-200">
                <div class="flex items-center h-16">
                    <!-- Mobile menu toggle, controls the 'mobileMenuOpen' state. -->
                    <button
                        type="button"
                        class="p-2 -ml-2 bg-white rounded-md text-slate-400 hover:text-slate-500 lg:hidden"
                        @click="showMenu = true"
                    >
                        <span class="sr-only">Open menu</span>
                        <Bars3Icon
                            class="w-6 h-6 stroke-2"
                            aria-hidden="true"
                        />
                    </button>

                    <!-- Logo -->
                    <div class="flex ml-4 text-slate-900 lg:ml-0">
                        <Link :href="route('home')">
                        <span class="sr-only">Calypso</span>
                        <ApplicationLogo class="hidden w-auto h-8 sm:inline" />
                        <ApplicationLogoIcon class="w-auto h-8 sm:hidden" />
                        </Link>
                    </div>

                    <!-- Flyout menus -->
                    <PopoverGroup class="hidden lg:ml-8 lg:block lg:self-stretch">
                        <div class="flex h-full space-x-8">
                            <Link
                                :href="route('home')"
                                class="flex items-center text-sm font-medium text-slate-700 hover:text-slate-800"
                            >{{ t('layout.navbar-main.home') }}</Link>

                            <Popover
                                v-slot="{ open, close }"
                                class="z-30 flex"
                            >
                                <div class="relative flex">
                                    <PopoverButton
                                        type="button"
                                        :class="['flex items-center pt-px -mb-px text-sm font-medium transition-colors duration-200 ease-out border-b-2 focus:outline-none', open ? 'border-indigo-600 text-indigo-600' : 'border-transparent text-slate-700 hover:text-slate-800']"
                                    >
                                        {{ t('layout.navbar-main.jewellery') }}
                                    </PopoverButton>
                                </div>

                                <transition
                                    enter-active-class="transition duration-200 ease-out"
                                    enter-from-class="opacity-0"
                                    enter-to-class="opacity-100"
                                    leave-active-class="transition duration-150 ease-in"
                                    leave-from-class="opacity-100"
                                    leave-to-class="opacity-0"
                                >
                                    <PopoverPanel class="absolute inset-x-0 text-sm top-full text-slate-500">
                                        <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
                                        <div
                                            class="absolute inset-0 bg-white shadow top-1/2"
                                            aria-hidden="true"
                                        ></div>

                                        <div class="relative bg-white">
                                            <div class="px-8 mx-auto max-w-7xl">
                                                <div class="grid grid-cols-2 py-16 gap-y-10 gap-x-8">
                                                    <div class="grid grid-cols-2 col-start-2 gap-x-8">
                                                        <div
                                                            v-if="earringsCategory"
                                                            class="relative text-base group sm:text-sm"
                                                        >
                                                            <div class="overflow-hidden bg-gray-100 rounded-lg aspect-w-3 aspect-h-1 group-hover:opacity-75">
                                                                <img
                                                                    data-src="/images/categories/earrings.jpg"
                                                                    :alt="earringsCategory.name"
                                                                    class="object-cover object-center lazyload"
                                                                >
                                                            </div>

                                                            <Link
                                                                :href="route('shop.category', { shop_categories: earringsCategory.slug_path })"
                                                                class="block mt-6 font-medium text-slate-900"
                                                                :onStart="close"
                                                            >
                                                            <span
                                                                class="absolute inset-0 z-10"
                                                                aria-hidden="true"
                                                            ></span>
                                                            {{ earringsCategory.name }}
                                                            </Link>
                                                            <p
                                                                aria-hidden="true"
                                                                class="mt-1"
                                                            >{{ t('layout.navbar-main.shop-now') }}</p>
                                                        </div>

                                                        <div
                                                            v-if="pendantsCategory"
                                                            class="relative text-base group sm:text-sm"
                                                        >
                                                            <div class="overflow-hidden bg-gray-100 rounded-lg aspect-w-3 aspect-h-1 group-hover:opacity-75">
                                                                <img
                                                                    data-src="/images/categories/pendants.jpg"
                                                                    :alt="pendantsCategory.name"
                                                                    class="object-cover object-center lazyload"
                                                                >
                                                            </div>

                                                            <Link
                                                                :href="route('shop.category', { shop_categories: pendantsCategory.slug_path })"
                                                                class="block mt-6 font-medium text-slate-900"
                                                                :onStart="close"
                                                            >
                                                            <span
                                                                class="absolute inset-0 z-10"
                                                                aria-hidden="true"
                                                            ></span>
                                                            {{ pendantsCategory.name }}
                                                            </Link>
                                                            <p
                                                                aria-hidden="true"
                                                                class="mt-1"
                                                            >{{ t('layout.navbar-main.shop-now') }}</p>
                                                        </div>
                                                    </div>

                                                    <div class="grid grid-cols-3 row-start-1 text-sm gap-y-10 gap-x-8">
                                                        <div>
                                                            <template v-if="jewelleryCategory">
                                                                <p
                                                                    id="women-heading"
                                                                    class="font-medium text-slate-900"
                                                                >{{ t('layout.navbar-main.menu.women') }}</p>
                                                                <ul
                                                                    role="list"
                                                                    aria-labelledby="women-heading"
                                                                    class="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                                >
                                                                    <li class="flex">
                                                                        <!-- class="{{ Request::is("*/{$category->slug_path}") ? '' : 'hover:text-slate-800' }}" -->
                                                                        <Link
                                                                            :href="route('shop.category', { shop_categories: jewelleryCategory.slug_path })"
                                                                            :onStart="close"
                                                                        >
                                                                        {{ t('layout.navbar-main.all-products') }}
                                                                        </Link>
                                                                    </li>

                                                                    <li
                                                                        v-for="category in jewelleryCategory.descendants.filter((category) => !['kids-jewellery', 'collections'].includes(category.code) && !!category.code)"
                                                                        :key="category.id"
                                                                        class="flex"
                                                                    >
                                                                        <!-- class="{{ Request::is(" */{$category->slug_path}") ? '' : 'hover:text-slate-800' }}" -->
                                                                        <Link
                                                                            :href="route('shop.category', category.slug_path)"
                                                                            :onStart="close"
                                                                        >
                                                                        {{ category.name }}
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </template>
                                                        </div>

                                                        <div>
                                                            <template v-if="kidsJewelleryCategory">
                                                                <p
                                                                    id="kids-heading"
                                                                    class="font-medium text-slate-900"
                                                                >{{ t('layout.navbar-main.menu.kids') }}</p>
                                                                <ul
                                                                    role="list"
                                                                    aria-labelledby="kids-heading"
                                                                    class="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                                >
                                                                    <li class="flex">
                                                                        <!-- class="{{ Request::is("*/{$category->slug_path}") ? '' : 'hover:text-slate-800' }}" -->
                                                                        <Link
                                                                            :href="route('shop.category', kidsJewelleryCategory.slug_path)"
                                                                            :onStart="close"
                                                                        >{{ t('layout.navbar-main.all-products') }}</Link>
                                                                    </li>

                                                                    <li
                                                                        v-for="category in kidsJewelleryCategory.descendants"
                                                                        :key="category.id"
                                                                        class="flex"
                                                                        :onStart="close"
                                                                    >
                                                                        <!-- class="{{ Request::is("*/{$category->slug_path}") ? '' : 'hover:text-slate-800' }}" -->
                                                                        <Link :href="route('shop.category', category.slug_path)">
                                                                        {{ category.name }}
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </template>
                                                        </div>

                                                        <div>
                                                            <template v-if="collectionsCategory">
                                                                <p
                                                                    id="collections-heading"
                                                                    class="font-medium text-slate-900"
                                                                >{{ collectionsCategory.name }}</p>
                                                                <ul
                                                                    role="list"
                                                                    aria-labelledby="collections-heading"
                                                                    class="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                                                >
                                                                    <li
                                                                        v-for="category in collectionsCategory.descendants"
                                                                        :key="category.id"
                                                                        class="flex"
                                                                    >
                                                                        <!-- class="{{ Request::is(" */{$category->slug_path}") ? '' : 'hover:text-slate-800' }}" -->
                                                                        <Link
                                                                            :href="route('shop.category', category.slug_path)"
                                                                            :onStart="close"
                                                                        >
                                                                        {{ category.name }}
                                                                        </Link>
                                                                    </li>

                                                                    <li
                                                                        v-if="kabbalahCategory"
                                                                        class="flex"
                                                                    >
                                                                        <!-- class="{{ Request::is(" */{$category->slug_path}") ? '' : 'hover:text-slate-800' }}" -->
                                                                        <Link
                                                                            :href="route('shop.category', kabbalahCategory.slug_path)"
                                                                            :onStart="close"
                                                                        >
                                                                        {{ kabbalahCategory.name }}
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </PopoverPanel>
                                </transition>
                            </Popover>

                            <Link
                                v-if="silverwareCategory"
                                :href="route('shop.category', silverwareCategory.slug_path)"
                                class="flex items-center text-sm font-medium text-slate-700 hover:text-slate-800"
                            >
                            {{ silverwareCategory.name }}
                            </Link>

                            <Link
                                :href="route('shop.outlet')"
                                class="flex items-center text-sm font-medium text-orange-700 hover:text-orange-800"
                            >{{ t('layout.navbar-main.outlet') }} %</Link>
                            <Link
                                :href="route('brands')"
                                class="flex items-center text-sm font-medium text-slate-700 hover:text-slate-800"
                            >{{ t('layout.navbar-main.brands') }}</Link>
                            <Link
                                :href="route('reviews', { type: 'shop' })"
                                class="flex items-center text-sm font-medium text-slate-700 hover:text-slate-800"
                            >{{ t('layout.navbar-main.reviews') }}</Link>
                        </div>
                    </PopoverGroup>

                    <div class="flex items-center ml-auto">
                        <!-- {{-- <div class="flex">
                            <a href="#" class="p-2 text-slate-400 hover:text-slate-500">
                                <span class="sr-only">{{ t('layout.navbar-main.search') }}</span>
                                <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </a>
                        </div> -->

                        <div class="flex">
                            <a
                                :href="$page.props.user ? route('profile.orders') : route('login')"
                                class="p-2 text-slate-400 hover:text-slate-500"
                            >
                                <span class="sr-only">{{ t('layout.navbar-main.login') }}</span>
                                <UserIcon class="w-6 h-6" />
                            </a>
                        </div>

                        <div
                            v-if="$page.props.user?.is_admin"
                            class="flex"
                        >
                            <a
                                :href="route('manage.catalog-categories.index')"
                                class="p-2 text-slate-400 hover:text-slate-500"
                            >
                                <span class="sr-only">Management</span>
                                <Cog6ToothIcon class="w-6 h-6" />
                            </a>
                        </div>

                        <div class="flex">
                            <Link
                                :href="route('wishlist')"
                                class="p-2 text-slate-400 hover:text-slate-500"
                            >
                            <span class="sr-only">{{ t('layout.navbar-main.wishlist') }}</span>
                            <HeartIcon class="w-6 h-6" />
                            </Link>
                        </div>

                        <!-- Cart -->
                        <div class="flex">
                            <a
                                href="#"
                                class="flex items-center p-2 -mr-2 group"
                                @click="cart.show = true"
                            >
                                <ShoppingCartIcon class="flex-shrink-0 w-6 h-6 text-slate-400 group-hover:text-slate-500" />
                                <span class="ml-2 text-sm font-medium text-slate-700 group-hover:text-slate-800">{{ cart.loaded ? cart.items.length : $page.props.cart.quantity }}</span>
                                <span class="sr-only">items in cart, view bag</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <TransitionRoot
        as="template"
        :show="showMenu"
    >
        <Dialog
            as="div"
            class="relative z-50 lg:hidden"
            aria-labelledby="menu-title"
            role="dialog"
            aria-modal="true"
            @close="showMenu = false"
        >
            <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </TransitionChild>

            <div class="fixed inset-0 flex">
                <TransitionChild
                    as="template"
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full"
                >
                    <DialogPanel class="relative flex flex-col w-full max-w-xs pb-12 overflow-y-auto bg-white shadow-xl">
                        <div class="flex px-4 pt-5 pb-2">
                            <button
                                type="button"
                                class="inline-flex items-center justify-center p-2 -m-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                @click="showMenu = false"
                            >
                                <span class="sr-only">Close menu</span>
                                <XMarkIcon
                                    class="w-6 h-6 stroke-2"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>

                        <!-- Links -->
                        <div class="mt-2">
                            <div
                                id="mobile-menu-jewellery-panel"
                                class="px-4 pt-4 pb-8 space-y-10"
                                aria-labelledby="mobile-menu-jewellery-tab"
                                role="tabpanel"
                            >
                                <div v-if="jewelleryCategory">
                                    <p
                                        :id="`mobile-menu-jewellery-${jewelleryCategory.id}-heading`"
                                        class="font-medium text-gray-900"
                                    >{{ t('layout.navbar-main.menu.women') }}</p>
                                    <ul
                                        role="list"
                                        :aria-labelledby="`mobile-menu-jewellery-${jewelleryCategory.id}-heading`"
                                        class="flex flex-col mt-6 space-y-6"
                                    >
                                        <li class="flow-root">
                                            <Link
                                                :href="route('shop.category', jewelleryCategory.slug_path)"
                                                :class="['-m-2 p-2 block', route().current(`*/${jewelleryCategory.slug_path}`) ? 'text-indigo-600 hover:text-indigo-500' : 'text-gray-500 hover:text-gray-800']"
                                            >
                                            {{ t('layout.navbar-main.all-products') }}
                                            </Link>
                                        </li>

                                        <li
                                            v-for="category in jewelleryCategory.descendants.filter((category) => !['kids-jewellery', 'collections'].includes(category.code) && !!category.code)"
                                            :key="category.id"
                                            class="flow-root"
                                        >
                                            <Link
                                                :href="route('shop.category', category.slug_path)"
                                                :class="['-m-2 p-2 block', route().current(`*/${category.slug_path}`) ? 'text-indigo-600 hover:text-indigo-500' : 'text-gray-500 hover:text-gray-800']"
                                            >
                                            {{ category.name }}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div v-if="kidsJewelleryCategory">
                                    <p
                                        :id="`mobile-menu-jewellery-${kidsJewelleryCategory.id}-heading`"
                                        class="font-medium text-gray-900"
                                    >{{ t('layout.navbar-main.menu.kids') }}</p>
                                    <ul
                                        role="list"
                                        :aria-labelledby="`mobile-menu-jewellery-${kidsJewelleryCategory.id}-heading`"
                                        class="flex flex-col mt-6 space-y-6"
                                    >
                                        <li class="flow-root">
                                            <Link
                                                :href="route('shop.category', kidsJewelleryCategory.slug_path)"
                                                :class="['-m-2 p-2 block', route().current(`*/${jewelleryCategory.slug_path}`) ? 'text-indigo-600 hover:text-indigo-500' : 'text-gray-500 hover:text-gray-800']"
                                            >
                                            {{ t('layout.navbar-main.all-products') }}
                                            </Link>
                                        </li>

                                        <li
                                            v-for="category in kidsJewelleryCategory.descendants"
                                            :key="category.id"
                                            class="flow-root"
                                        >
                                            <Link
                                                :href="route('shop.category', category.slug_path)"
                                                :class="['-m-2 p-2 block', route().current(`*/${category.slug_path}`) ? 'text-indigo-600 hover:text-indigo-500' : 'text-gray-500 hover:text-gray-800']"
                                            >
                                            {{ category.name }}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div v-if="silverwareCategory">
                                    <p
                                        :id="`mobile-menu-jewellery-${silverwareCategory.id}-heading`"
                                        class="font-medium text-gray-900"
                                    >{{ silverwareCategory.name }}</p>
                                    <ul
                                        role="list"
                                        :aria-labelledby="`mobile-menu-jewellery-${silverwareCategory.id}-heading`"
                                        class="flex flex-col mt-6 space-y-6"
                                    >
                                        <li class="flow-root">
                                            <Link
                                                :href="route('shop.category', silverwareCategory.slug_path)"
                                                :class="['-m-2 p-2 block', route().current(`*/${jewelleryCategory.slug_path}`) ? 'text-indigo-600 hover:text-indigo-500' : 'text-gray-500 hover:text-gray-800']"
                                            >
                                            {{ t('layout.navbar-main.all-products') }}
                                            </Link>
                                        </li>

                                        <li
                                            v-for="category in silverwareCategory.descendants"
                                            :key="category.id"
                                            class="flow-root"
                                        >
                                            <Link
                                                :href="route('shop.category', category.slug_path)"
                                                :class="['-m-2 p-2 block', route().current(`*/${category.slug_path}`) ? 'text-indigo-600 hover:text-indigo-500' : 'text-gray-500 hover:text-gray-800']"
                                            >
                                            {{ category.name }}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div v-if="collectionsCategory">
                                    <p
                                        :id="`mobile-menu-jewellery-${collectionsCategory.id}-heading`"
                                        class="font-medium text-gray-900"
                                    >{{ collectionsCategory.name }}</p>
                                    <ul
                                        role="list"
                                        :aria-labelledby="`mobile-menu-jewellery-${collectionsCategory.id}-heading`"
                                        class="flex flex-col mt-6 space-y-6"
                                    >
                                        <li
                                            v-for="category in collectionsCategory.descendants"
                                            :key="category.id"
                                            class="flow-root"
                                        >
                                            <Link
                                                :href="route('shop.category', category.slug_path)"
                                                :class="['-m-2 p-2 block', route().current(`*/${category.slug_path}`) ? 'text-indigo-600 hover:text-indigo-500' : 'text-gray-500 hover:text-gray-800']"
                                            >
                                            {{ category.name }}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="px-4 py-6 space-y-6 border-t border-gray-200">
                            <div class="flow-root">
                                <Link
                                    :href="route('home')"
                                    class="block p-2 -m-2 font-medium text-gray-700 hover:text-gray-800"
                                >{{ t('layout.navbar-main.home') }}</Link>
                            </div>

                            <div class="flow-root">
                                <Link
                                    :href="route('shop.outlet')"
                                    class="block p-2 -m-2 font-medium text-orange-700 hover:text-orange-800"
                                >{{ t('layout.navbar-main.outlet') }} %</Link>
                            </div>

                            <div class="flow-root">
                                <Link
                                    :href="route('brands')"
                                    class="block p-2 -m-2 font-medium text-gray-700 hover:text-gray-800"
                                >{{ t('layout.navbar-main.brands') }}</Link>
                            </div>

                            <div class="flow-root">
                                <Link
                                    :href="route('reviews', { type: 'shop' })"
                                    class="block p-2 -m-2 font-medium text-gray-700 hover:text-gray-800"
                                >{{ t('layout.navbar-main.reviews') }}</Link>
                            </div>
                        </div>

                        <div class="px-4 py-6 space-y-6 border-t border-gray-200">
                            <template v-if="!$page.props.user">
                                <div class="flow-root">
                                    <a
                                        :href="route('login')"
                                        class="block p-2 -m-2 font-medium text-gray-700 hover:text-gray-800"
                                    >{{ t('layout.navbar-main.login') }}</a>
                                </div>

                                <div class="flow-root">
                                    <a
                                        :href="route('register')"
                                        class="block p-2 -m-2 font-medium text-gray-700 hover:text-gray-800"
                                    >{{ t('layout.navbar-main.register') }}</a>
                                </div>
                            </template>

                            <template v-else>
                                <div class="flow-root">
                                    <a
                                        :href="route('profile.orders')"
                                        class="block p-2 -m-2 font-medium text-gray-700 hover:text-gray-800"
                                    >{{ t('layout.navbar-main.orders') }}</a>
                                </div>

                                <div class="flow-root">
                                    <a
                                        :href="route('profile.wishlist')"
                                        class="block p-2 -m-2 font-medium text-gray-700 hover:text-gray-800"
                                    >{{ t('layout.navbar-main.wishlist') }}</a>
                                </div>

                                <div class="flow-root">
                                    <a
                                        :href="route('profile.settings')"
                                        class="block p-2 -m-2 font-medium text-gray-700 hover:text-gray-800"
                                    >{{ t('layout.navbar-main.settings') }}</a>
                                </div>
                            </template>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
